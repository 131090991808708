export function cups(CUPS) {
	if (CUPS == "" || CUPS == null) return true;
	Math.fmod = function(a, b) {
		return Number((a - Math.floor(a / b) * b).toPrecision(8));
	};
	var status = false;
	var RegExPattern = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;
	if (CUPS.match(RegExPattern) && CUPS != "") {
		var CUPS_16 = CUPS.substr(2, 16);
		var control = CUPS.substr(18, 2);
		var l = ["T", "R", "W", "A", "G", "M", "Y", "F", "P", "D", "X", "B"];
		l = [...l, "N", "J", "Z", "S", "Q", "V", "H", "L", "C", "K", "E"];
		var fmodv = Math.fmod(CUPS_16, 529);
		var imod = parseInt(fmodv);
		var quotient = Math.floor(imod / 23);
		var remainder = imod % 23;

		var dc1 = l[quotient];
		var dc2 = l[remainder];
		status = control == dc1 + dc2;
	} else {
		status = "El CUPS no es válido";
	}
	return status;
}

export function spainIdType(str) {
	var DNI_REGEX = /^(\d{8})([A-Z])$/;
	var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
	var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
	if (str.match(DNI_REGEX)) {
		return "dni";
	}
	if (str.match(CIF_REGEX)) {
		return "cif";
	}
	if (str.match(NIE_REGEX)) {
		return "nie";
	}
}

export function ValidateSpanishID(str) {
	if (!str) return true;
	var DNI_REGEX = /^(\d{8})([A-Z])$/;
	var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
	var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

	var ValidateSpanishID = function(str) {
		// Ensure upcase and remove whitespace
		str = str.toUpperCase().replace(/\s/, "");

		var valid = false;
		var type = spainIdType(str);

		switch (type) {
			case "dni":
				valid = validDNI(str);
				break;
			case "nie":
				valid = validNIE(str);
				break;
			case "cif":
				valid = validCIF(str);
				break;
		}
		return valid || "El identificador no es válido";
	};

	var validDNI = function(dni) {
		var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
		var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

		return letter == dni.charAt(8);
	};

	var validNIE = function(nie) {
		// Change the initial letter for the corresponding number and validate as DNI
		var nie_prefix = nie.charAt(0);

		switch (nie_prefix) {
			case "X":
				nie_prefix = 0;
				break;
			case "Y":
				nie_prefix = 1;
				break;
			case "Z":
				nie_prefix = 2;
				break;
		}

		return validDNI(nie_prefix + nie.substr(1));
	};

	var validCIF = function(cif) {
		var match = cif.match(CIF_REGEX);
		var letter = match[1],
			number = match[2],
			control = match[3];

		var even_sum = 0;
		var odd_sum = 0;
		var n;

		for (var i = 0; i < number.length; i++) {
			n = parseInt(number[i], 10);

			// Odd positions (Even index equals to odd position. i=0 equals first position)
			if (i % 2 === 0) {
				// Odd positions are multiplied first.
				n *= 2;

				// If the multiplication is bigger than 10 we need to adjust
				odd_sum += n < 10 ? n : n - 9;

				// Even positions
				// Just sum them
			} else {
				even_sum += n;
			}
		}

		var control_digit = 10 - (even_sum + odd_sum).toString().substr(-1);
		var control_letter = "JABCDEFGHI".substr(control_digit, 1);

		// Control must be a digit
		if (letter.match(/[ABEH]/)) {
			return control == control_digit;

			// Control must be a letter
		} else if (letter.match(/[KPQS]/)) {
			return control == control_letter;

			// Can be either
		} else {
			return control == control_digit || control == control_letter;
		}
	};

	return ValidateSpanishID(str);
}

export function isNumber(v) {
	if (v == "" || v == null) return true;
	if (/,/g.test(v)) {
		return "La parte decimal va separada por punto, no por coma";
	}
	return !isNaN(parseFloat(v)) || "Tiene que ser un número";
}

export function phone(p) {
	if (p == "" || p == null) return true;
	p = p.replace(/[^0-9.]/g, "");
	return p.length == 9 ? true : "El teléfono no es válido";
}

export function email(v) {
	if (!v) return true;
	const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return pattern.test(v) || "El email no es válido";
}

export function req(value) {
	return value != null || "Este campo es obligatorio";
}

export function iban(input) {
	if (input == null || input.length == 0) return true;
	const CODE_LENGTHS = {
		AD: 24,
		AE: 23,
		AT: 20,
		AZ: 28,
		BA: 20,
		BE: 16,
		BG: 22,
		BH: 22,
		BR: 29,
		CH: 21,
		CR: 21,
		CY: 28,
		CZ: 24,
		DE: 22,
		DK: 18,
		DO: 28,
		EE: 20,
		ES: 24,
		FI: 18,
		FO: 18,
		FR: 27,
		GB: 22,
		GI: 23,
		GL: 18,
		GR: 27,
		GT: 28,
		HR: 21,
		HU: 28,
		IE: 22,
		IL: 23,
		IS: 26,
		IT: 27,
		JO: 30,
		KW: 30,
		KZ: 20,
		LB: 28,
		LI: 21,
		LT: 20,
		LU: 20,
		LV: 21,
		MC: 27,
		MD: 24,
		ME: 22,
		MK: 19,
		MR: 27,
		MT: 31,
		MU: 30,
		NL: 18,
		NO: 15,
		PK: 24,
		PL: 28,
		PS: 29,
		PT: 25,
		QA: 29,
		RO: 24,
		RS: 22,
		SA: 24,
		SE: 24,
		SI: 19,
		SK: 24,
		SM: 27,
		TN: 24,
		TR: 26,
		AL: 28,
		BY: 28,
		CR: 22,
		EG: 29,
		GE: 22,
		IQ: 23,
		LC: 32,
		SC: 31,
		ST: 25,
		SV: 28,
		TL: 23,
		UA: 29,
		VA: 22,
		VG: 24,
		XK: 20,
	};
	var iban = String(input)
			.toUpperCase()
			.replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
		code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
		digits;
	// check syntax and length
	if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
		return 'Este IBAN no es válido';
	}
	// rearrange country code and check digits, and convert chars to ints
	digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
		return letter.charCodeAt(0) - 55;
	});
	// final check
	function mod97(string) {
		var checksum = string.slice(0, 2),
			fragment;
		for (var offset = 2; offset < string.length; offset += 7) {
			fragment = String(checksum) + string.substring(offset, offset + 7);
			checksum = parseInt(fragment, 10) % 97;
		}
		return checksum;
	}
	return mod97(digits) === 1 || 'Este IBAN no es válido';
}
